// @file The main store for templates gallery on the dashboard.
import { isApacCountry, isEuCountry, isUsCountry } from '@@/bits/country'
import { useDashGalleryTemplatesStore } from '@@/pinia/dash_gallery_templates_store'
import { useDashStore } from '@@/pinia/dash_store'
import type { WallGalleryTemplate } from '@@/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const TEMPLATE_ITEM_IMAGE_ASPECT_RATIO = 213 / 120
export const DETAILS_MODAL_CAROUSEL_ASPECT_RATIO = 16 / 9

export const useDashGalleryStore = defineStore('dashGalleryStore', () => {
  const dashGalleryTemplatesStore = useDashGalleryTemplatesStore()
  const dashStore = useDashStore()
  // State
  const clickedTemplateId = ref<string>('')
  const xTemplateDetailsModal = ref(false)

  // Getters
  const allTemplates = computed<WallGalleryTemplate[]>(() => {
    return dashGalleryTemplatesStore.galleryTemplates
  })

  const clickedTemplate = computed<WallGalleryTemplate | undefined>(() => {
    return allTemplates.value.find((template) => template.galleryTemplateId === clickedTemplateId.value)
  })

  // All the templates that are currently being displayed
  const currentTemplates = ref<WallGalleryTemplate[]>(allTemplates.value)
  const numberOfCurrentTemplates = computed(() => {
    return currentTemplates.value.length
  })
  const isCurrentTemplatesEmpty = computed(() => {
    return numberOfCurrentTemplates.value === 0
  })
  const isFetchingGalleryPageData = computed(() => !dashGalleryTemplatesStore.isGalleryTemplatesLoaded)

  const padletOfTheMonth = computed<WallGalleryTemplate | undefined>(() => {
    const countryCode = dashStore.detectedCountryCode

    // Try to find locale-specific POTM template
    let localeSpecificTemplate: WallGalleryTemplate | undefined

    if (isUsCountry(countryCode)) {
      localeSpecificTemplate = allTemplates.value.find((template) => template.audience === 'potm_us')
    } else if (isEuCountry(countryCode)) {
      localeSpecificTemplate = allTemplates.value.find((template) => template.audience === 'potm_eu')
    } else if (isApacCountry(countryCode)) {
      localeSpecificTemplate = allTemplates.value.find((template) => template.audience === 'potm_apac')
    }

    // Return locale-specific template if found, otherwise fall back to default POTM
    return localeSpecificTemplate ?? allTemplates.value.find((template) => template.audience === 'potm')
  })

  // Actions
  function initialize(): void {
    void dashGalleryTemplatesStore.fetchGalleryTemplates()
  }

  function showTemplateDetailsModal(): void {
    xTemplateDetailsModal.value = true
  }

  function hideTemplateDetailsModal(): void {
    xTemplateDetailsModal.value = false
  }

  function updateClickedTemplateId(templateId: string): void {
    clickedTemplateId.value = templateId
  }

  function getTemplateById(templateId: string): WallGalleryTemplate | undefined {
    return allTemplates.value.find((template) => template.galleryTemplateId === templateId)
  }

  function getPreviousTemplate(currentTemplate: WallGalleryTemplate): WallGalleryTemplate {
    const index = currentTemplates.value.indexOf(currentTemplate)
    if (index === -1) {
      return currentTemplates.value[0]
    } else if (index === 0) {
      return currentTemplates.value[currentTemplates.value.length - 1]
    }
    return currentTemplates.value[index - 1]
  }

  function getNextTemplate(currentTemplate: WallGalleryTemplate): WallGalleryTemplate {
    const index = currentTemplates.value.indexOf(currentTemplate)
    if (index === -1) {
      return currentTemplates.value[currentTemplates.value.length - 1]
    } else if (index === currentTemplates.value.length - 1) {
      return currentTemplates.value[0]
    }
    return currentTemplates.value[index + 1]
  }

  return {
    // State
    clickedTemplateId,
    xTemplateDetailsModal,

    // Getters
    allTemplates,
    currentTemplates,
    numberOfCurrentTemplates,
    isCurrentTemplatesEmpty,
    clickedTemplate,
    isFetchingGalleryPageData,
    padletOfTheMonth,

    // Actions,
    initialize,
    getTemplateById,
    getPreviousTemplate,
    getNextTemplate,
    updateClickedTemplateId,
    showTemplateDetailsModal,
    hideTemplateDetailsModal,
  }
})

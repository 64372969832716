// @file The store for the search functionality on layout pickers displayed by DashLayoutPickerPage.vue and LayoutPickerDesktop.vue
import { trackEvent } from '@@/bits/analytics'
import { useDashLayoutPickerStore } from '@@/pinia/dash_layout_picker_store'
import { useDashStore } from '@@/pinia/dash_store'
import type { WallGalleryTemplate } from '@@/types'
import Fuse from 'fuse.js'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useDashLayoutPickerSearchStore = defineStore('dashLayoutPickerSearchStore', () => {
  const dashLayoutPickerStore = useDashLayoutPickerStore()
  const dashStore = useDashStore()

  const searchTerm = ref('')
  const isSearchBarInEditMode = ref(false)

  const hasSearchTerm = computed(() => searchTerm.value.trim().length > 0)

  // This is true only if the user has executed a search and the search had results
  const hasSearchResults = computed(
    () => dashLayoutPickerStore.galleryTemplatesSearchResults.length > 0 && hasSearchTerm.value,
  )
  function setSearchTerm(term: string): void {
    searchTerm.value = term
    if (searchTerm.value.length > 0) {
      trackEvent('Layout picker gallery search', 'Searched gallery', searchTerm.value)
    }
  }

  function clearSearchTerm(): void {
    searchTerm.value = ''
  }

  function navigateToGalleryPage(): void {
    dashStore.closeLayoutPicker()
    dashStore.switchView({ gallery: 'all' })
  }

  function filterBySearch(templates: WallGalleryTemplate[], searchTerm: string): WallGalleryTemplate[] {
    if (!hasSearchTerm.value) return templates
    const fuseOptions = {
      keys: ['title', 'templateSynonyms', 'description'],
      threshold: 0.3, // Setting it low to get more relevant results
    }

    const fuse = new Fuse(templates, fuseOptions)
    // Mapping the results to get the original template object
    return fuse.search(searchTerm).map((result) => result.item)
  }

  return {
    searchTerm,
    isSearchBarInEditMode,
    hasSearchTerm,
    hasSearchResults,
    setSearchTerm,
    clearSearchTerm,
    navigateToGalleryPage,
    filterBySearch,
  }
})

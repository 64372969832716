/**
 * @file List of ISO 3166-1 alpha-2 country codes for APAC and EU countries.
 * See https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 for more information.
 */

const apacCountryCodes: string[] = [
  'AF', // Afghanistan
  'AU', // Australia
  'BD', // Bangladesh
  'BT', // Bhutan
  'BN', // Brunei Darussalam
  'KH', // Cambodia
  'CN', // China
  'HK', // Hong Kong, China
  'MO', // Macao, China
  'CK', // Cook Islands
  'KP', // Democratic People's Republic of Korea
  'FJ', // Fiji
  'IN', // India
  'ID', // Indonesia
  'JP', // Japan
  'KI', // Kiribati
  'LA', // Lao People's Democratic Republic
  'MY', // Malaysia
  'MV', // Maldives
  'MH', // Marshall Islands
  'FM', // Micronesia (Federated States of)
  'MN', // Mongolia
  'MM', // Myanmar
  'NR', // Nauru
  'NP', // Nepal
  'NZ', // New Zealand
  'PK', // Pakistan
  'PW', // Palau
  'PG', // Papua New Guinea
  'PH', // Philippines
  'KR', // Republic of Korea
  'WS', // Samoa
  'SG', // Singapore
  'SB', // Solomon Islands
  'LK', // Sri Lanka
  'TH', // Thailand
  'TL', // Timor-Leste
  'TO', // Tonga
  'TV', // Tuvalu
  'VU', // Vanuatu
  'VN', // Viet Nam
]

const euCountryCodes: string[] = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czechia
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
]

const isEuCountry = (countryCode: string): boolean => euCountryCodes.includes(countryCode)

const isApacCountry = (countryCode: string): boolean => apacCountryCodes.includes(countryCode)

const isUsCountry = (countryCode: string): boolean => countryCode === 'US'

export { apacCountryCodes, euCountryCodes, isApacCountry, isEuCountry, isUsCountry }

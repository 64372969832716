// @file The store that handles the fetching of gallery templates
import currentUser from '@@/bits/current_user'
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { buildUserIdCacheKey, fetchCachedQuery } from '@@/bits/query_client'
import { DashStartingStateApi } from '@@/dashboard/padlet_api'
import { SnackbarNotificationType } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { JsonApiResponse, WallGalleryTemplate } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum GalleryTemplatesStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Error = 'Error',
}

export const useDashGalleryTemplatesStore = defineStore('dashGalleryTemplatesStore', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()

  const galleryTemplates = ref<WallGalleryTemplate[]>([])
  const galleryTemplatesStatus = ref(GalleryTemplatesStatus.Loading)
  const isGalleryTemplatesLoaded = computed(
    (): boolean => galleryTemplatesStatus.value === GalleryTemplatesStatus.Completed,
  )

  async function fetchGalleryTemplates(): Promise<void> {
    galleryTemplatesStatus.value = GalleryTemplatesStatus.Loading
    try {
      await fetchCachedQuery<JsonApiResponse<WallGalleryTemplate>>({
        cacheKey: ['fetchGalleryTemplates', buildUserIdCacheKey(currentUser.id)],
        queryFn: async () => await DashStartingStateApi.fetchGalleryTemplates(),
        onFetchSuccess: (response) => {
          const data = response.data as Array<JsonAPIResource<WallGalleryTemplate>>
          galleryTemplates.value = data.map((template) => template.attributes)
          galleryTemplatesStatus.value = GalleryTemplatesStatus.Completed
        },
      })
    } catch (e) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error fetching gallery templates'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'DashGalleryTemplatesStore' })
      galleryTemplatesStatus.value = GalleryTemplatesStatus.Error
    }
  }

  return {
    galleryTemplates,
    galleryTemplatesStatus,
    isGalleryTemplatesLoaded,
    fetchGalleryTemplates,
  }
})

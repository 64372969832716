// @file Dashboard navigation store
import { LIBRARY_ACCOUNT_FILTERS, TENANT_USER_ACCOUNT_FILTERS, USER_ACCOUNT_FILTERS } from '@@/bits/collections_helper'
import {
  dashViewUrl,
  getDashViewFromUrlParams,
  getDashViewUrlFromStorage,
  saveDashViewUrlIntoStorage,
} from '@@/bits/dash_helper'
import { isAppUsing } from '@@/bits/flip'
import window from '@@/bits/global'
import { __ } from '@@/bits/intl'
import {
  buildUrlFromPath,
  currentHostWithProtocol,
  getPathFromUrl,
  setSearchParam,
  transformUrl,
} from '@@/bits/location'
import { snakeToKebabCase } from '@@/bits/sanitize_and_transform_text'
import { OzBaseButtonHrefMode } from '@@/library/v4/components/OzBaseButton.vue'
import {
  OzTabsWithSeparatorButtonColorPreset,
  type OzTabsWithSeparatorButtonProps,
} from '@@/library/v4/components/OzTabsWithSeparator.vue'
import { useDashAccountsStore } from '@@/pinia/dash_accounts_store'
import { useDashCollectionsStore } from '@@/pinia/dash_collections_store'
import { useDashGalleryFilterStore } from '@@/pinia/dash_gallery_filter_store'
import { useDashMakeStore } from '@@/pinia/dash_make_store'
import { useDashStore } from '@@/pinia/dash_store'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type { AccountMenuItem, Folder, Library } from '@@/types'
import { CollectionKeyTypes, type CollectionKey } from '@@/types/collections'
import type { DashView } from '@@/types/dash'
import { MobilePage } from '@@/types/dash'
import { isEmpty } from 'es-toolkit/compat'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum DashTabs {
  HOME = 'Home',
  GALLERY = 'Gallery',
  LEARN = 'Learn',
  JOIN = 'Join',
  MAKE = 'Make',
}

export const useDashNavigationStore = defineStore('dashNavigationStore', () => {
  const dashStore = useDashStore()
  const dashAccountsStore = useDashAccountsStore()
  const dashCollectionsStore = useDashCollectionsStore()
  const dashMakeStore = useDashMakeStore()
  const windowSizeStore = useWindowSizeStore()
  const dashGalleryFilterStore = useDashGalleryFilterStore()

  // State
  const currentTabKey = ref<DashTabs>(DashTabs.HOME)

  // Getters
  const currentHomePath = computed<string>(() => {
    return dashAccountsStore.isCurrentAccountLibrary ? dashAccountsStore.currentLibraryDashboardPath : '/dashboard'
  })
  const tabs = computed<OzTabsWithSeparatorButtonProps[]>(() => {
    return [
      {
        key: DashTabs.HOME,
        text: __('Home'),
        href: generateCurrentHomeUrl(),
        hrefMode:
          currentTabKey.value === DashTabs.HOME
            ? OzBaseButtonHrefMode.DisplayOnly
            : OzBaseButtonHrefMode.HistoryPushState,
        testId: 'homeButtonTab',
      },
      ...(dashAccountsStore.currentUserOptions.canViewGallery === true && dashAccountsStore.canViewCrossLibraryGallery
        ? [
            {
              key: DashTabs.GALLERY,
              text: __('Gallery'),
              href: generateGalleryUrl(currentHomePath.value),
              hrefMode:
                currentTabKey.value === DashTabs.GALLERY
                  ? OzBaseButtonHrefMode.DisplayOnly
                  : OzBaseButtonHrefMode.HistoryPushState,
              testId: 'galleryButtonTab',
            },
          ]
        : []),
      {
        key: DashTabs.LEARN,
        text: __('Learn'),
        href: generateLearnUrl(currentHomePath.value),
        hrefMode:
          currentTabKey.value === DashTabs.LEARN
            ? OzBaseButtonHrefMode.DisplayOnly
            : OzBaseButtonHrefMode.HistoryPushState,
      },
      {
        key: DashTabs.JOIN,
        text: __('Join'),
        testId: 'joinButtonTab',
      },
      {
        key: DashTabs.MAKE,
        text: __('Make'),
        href: generateMakeUrl(currentHomePath.value),
        hrefMode:
          currentTabKey.value === DashTabs.MAKE
            ? OzBaseButtonHrefMode.DisplayOnly
            : OzBaseButtonHrefMode.HistoryPushState,
        colorPreset: OzTabsWithSeparatorButtonColorPreset.Primary,
        disabled: dashMakeStore.cannotMakeWallAndCannotUpgrade || dashStore.hasMigrationCompleted,
        tooltipText: dashMakeStore.cannotMakeWallAndCannotUpgrade ? dashMakeStore.unableToCreateWallText : undefined,
        xTitleTooltip: !dashMakeStore.cannotMakeWallAndCannotUpgrade,
        testId: 'makeButtonTab',
        startAdornmentText: '+',
      },
    ]
  })
  const defaultDashboardUrl = computed((): string => {
    // We return the user's personal dashboard url
    if (dashAccountsStore.defaultLibrary === undefined) {
      return dashViewUrl({ mobilePage: MobilePage.AccountsMenu }, { path: '/dashboard' })
    }

    const defaultLibraryUrl = transformUrl(dashAccountsStore.defaultLibrary.dashboardUrl, {
      searchParams: {
        mobile_page: MobilePage.Collection,
        filter: 'all',
      },
    })

    // We need to remove the currentHost from the defaultLibraryUrl since
    // getDashViewFromUrlParams handles pathname and not the entire url
    return defaultLibraryUrl.replace(currentHostWithProtocol(), '')
  })

  // Actions

  const generateHomeUrl = (prefixPath: string): string => {
    // We need to replace gallery filter with recents to prevent showing old gallery page in Home page when switching from mobile viewport to desktop viewport.
    // TODO: Remove this block when building the UI for mobile viewport.
    return dashViewUrl(
      {
        mobilePage: MobilePage.Collection,
        collectionKey:
          dashCollectionsStore.activeCollectionKey.indexKey !== 'gallery'
            ? dashCollectionsStore.activeCollectionKey
            : { typeKey: 'filter', indexKey: 'combined_recents' },
      },
      { path: prefixPath },
    )
  }

  const generateCurrentHomeUrl = (): string => {
    return generateHomeUrl(currentHomePath.value)
  }

  const generateGalleryUrl = (prefixPath: string): string => {
    return dashViewUrl(
      { mobilePage: MobilePage.CollectionsMenu },
      {
        path: `${prefixPath}/gallery`,
        searchParams: { audience: snakeToKebabCase(dashGalleryFilterStore.selectedAudience) },
      },
    )
  }

  const generateCurrentGalleryUrl = (): string => {
    return generateGalleryUrl(currentHomePath.value)
  }

  const generateMakeUrl = (prefixPath: string): string => {
    return dashViewUrl({ mobilePage: MobilePage.LayoutPicker }, { path: `${prefixPath}/make` })
  }

  const generateCurrentMakeUrl = (): string => {
    return generateMakeUrl(currentHomePath.value)
  }

  const generateLearnUrl = (prefixPath: string): string => {
    // No corresponding mobilePage for Padlet Home learn page. Let it show accounts menu for now
    return dashViewUrl({ mobilePage: MobilePage.AccountsMenu }, { path: `${prefixPath}/learn` })
  }

  const generateURLFromAccount = (account: AccountMenuItem): string => {
    const prefixPath = account.isLibrary ? getPathFromUrl(account.url) : '/dashboard'

    switch (currentTabKey.value) {
      case DashTabs.HOME:
        return generateHomeUrl(prefixPath)
      case DashTabs.GALLERY:
        return generateGalleryUrl(prefixPath)
      case DashTabs.MAKE:
        return generateMakeUrl(prefixPath)
      case DashTabs.LEARN:
        return generateLearnUrl(prefixPath)
      default:
        return dashViewUrl({ mobilePage: MobilePage.AccountsMenu })
    }
  }

  function switchToInitialTabAndView(): void {
    let dashView: DashView = getDashViewFromUrlParams()
    const dashViewUrlFromStorage = getDashViewUrlFromStorage()

    if (isEmpty(dashView) && dashViewUrlFromStorage !== null) {
      // Load last opened view URL and update dashView if visiting the dashboard without params
      window.history.pushState(null, '', dashViewUrlFromStorage)
      dashView = getDashViewFromUrlParams()
    }

    // If no valid mobile page exists or library slug exists for the slug or if folder id is invalid
    // we redirect the user to either recents or user > all
    if (
      isEmpty(dashView) ||
      isLibraryHidden(dashView) ||
      isAccessingHiddenPersonalAccountView(dashView) ||
      isMobilePageInvalid(dashView) ||
      isLibrarySlugInvalid(dashView) ||
      isFolderIdInvalid(dashView) ||
      // We still allow people to visit a hidden library dashboard via URL or redirected from other pages
      // There is a case when the user is on a library dashboard -> go to /dashboard/settings/dashboard -> hide the library -> go back to dashboard
      // The app should display Recents or the default library view instead of the hidden library view
      (isLibraryHidden(dashView) && isNavigatedFromDashboardSettings())
    ) {
      switchToRecentsOrDefaultLibraryView()
      return
    }

    // librarySlug should be valid at this point because we checked it with isLibrarySlugInvalid(dashView) above
    if (dashView.librarySlug !== undefined) {
      const libraryId = dashAccountsStore.librariesArray.find(
        (library: Library) => library.slug === dashView.librarySlug,
      )?.id
      if (libraryId !== undefined) {
        // Update the dashView with the current libraryId to display it on the dashboard
        dashView = { ...dashView, accountKey: { type: 'library', id: libraryId } }
      }
    } else {
      dashView = { ...dashView, accountKey: { type: 'user', id: dashAccountsStore.currentUser.id } }
    }

    // Ensure that the layout picker will display the default library on the first render on mobile view
    // Context: When you first visit a tab on the mobile nav bar, then open the layout picker, the default library should be selected. See the details in the method setInitialLibrarySelected in app/javascript/pinia/dash_layout_picker_store.ts
    if (isMobilePageNavATabView(dashView) && dashAccountsStore.defaultLibrary !== undefined) {
      dashView = { ...dashView, accountKey: { type: 'library', id: dashAccountsStore.defaultLibrary.id } }
    }

    switchTabAndViewFromDashView(dashView)
  }

  function switchTabAndViewFromDashView(dashView: DashView): void {
    // Set search params here when accesing new padlet home urls, which do not have a corresponding mobilePage on the old dashboard
    // e.g. "/dashboard/gallery", "/dashboard/learn"
    let mobilePage = MobilePage.Collection
    if (dashView.gallery != null) {
      currentTabKey.value = DashTabs.GALLERY
      mobilePage = MobilePage.CollectionsMenu
    } else if (dashView.make != null) {
      currentTabKey.value = DashTabs.MAKE
      mobilePage = MobilePage.LayoutPicker
    } else if (dashView.learn != null) {
      currentTabKey.value = DashTabs.LEARN
      mobilePage = MobilePage.Learn
    } else {
      currentTabKey.value = DashTabs.HOME
      mobilePage = dashView.mobilePage ?? MobilePage.Collection

      // Replace old fiter or invalid filter param name with the new filter param name in new dashboard
      if (dashView.filter === 'shared') {
        dashView.filter = 'combined_shared'
        setSearchParam('filter', 'combined_shared')
      } else if (dashView.filter === 'recents' || isCollectionFilterInvalid(dashView)) {
        dashView.filter = 'combined_recents'
        setSearchParam('filter', 'combined_recents')
      }
    }

    setSearchParam('mobile_page', mobilePage)
    dashView = { ...dashView, mobilePage }

    dashStore.switchView(dashView)
  }

  function switchTabAndViewFromUrlParams(): void {
    let dashView: DashView = getDashViewFromUrlParams()
    const dashViewUrlFromStorage = getDashViewUrlFromStorage()

    if (isEmpty(dashView) && dashViewUrlFromStorage !== null) {
      // Load last opened view URL and update dashView if visiting the dashboard without params
      window.history.pushState(null, '', dashViewUrlFromStorage)
      dashView = getDashViewFromUrlParams()
    }
    dashView.accountKey = dashCollectionsStore.currentAccountKey

    switchTabAndViewFromDashView(dashView)
  }

  function switchToRecentsOrDefaultLibraryView(): void {
    if (dashAccountsStore.user.hasRecentPadlets === true && dashAccountsStore.user.visible === true) {
      window?.history?.pushState(
        null,
        '',
        dashViewUrl({ mobilePage: MobilePage.Collection, filter: 'combined_recents' }, { path: '/dashboard' }),
      )

      switchTabAndViewFromUrlParams()
    } else {
      window?.history?.pushState(null, '', defaultDashboardUrl.value)
      let dashView = getDashViewFromUrlParams()

      if (dashAccountsStore.isNativeAccount && dashAccountsStore.defaultLibrary !== undefined) {
        // We need to update the accountKey since it is the native user accountKey by default
        dashView = { ...dashView, accountKey: { type: 'library', id: dashAccountsStore.defaultLibrary.id } }
      }

      switchTabAndViewFromDashView(dashView)
    }
  }

  function redirectToHome(collectionKey?: CollectionKey): void {
    let newCollectionKey: CollectionKey =
      collectionKey != null ? collectionKey : { typeKey: 'filter', indexKey: 'combined_recents' }

    // Defaults to `combined_recents` if filter collection is invalid
    if (newCollectionKey.typeKey === 'filter' && isCollectionFilterInvalid({ filter: newCollectionKey.indexKey })) {
      newCollectionKey = { typeKey: 'filter', indexKey: 'combined_recents' }
    }

    const homeUrl = dashViewUrl({ collectionKey: newCollectionKey }, { path: generateCurrentHomeUrl() })
    window.history.replaceState(null, '', homeUrl)
    switchTabAndViewFromUrlParams()
  }

  function isLibraryHidden(dashView: DashView): boolean {
    // exit early if there are no relevant library slug data in dashView
    if (dashView.librarySlug === undefined) return false

    const libraryId = dashAccountsStore.wallViewableAndVisibleLibrariesArray.find(
      (library: Library) => library.slug === dashView.librarySlug,
    )?.id
    if (libraryId !== undefined) return false
    return true
  }

  function isAccessingHiddenPersonalAccountView(dashView: DashView): boolean {
    return dashView.librarySlug === undefined && dashAccountsStore.currentUser.visible === false
  }

  function isMobilePageInvalid(dashView: DashView): boolean {
    if (dashView.mobilePage === undefined) return false
    return MobilePage[dashView.mobilePage as string] === undefined
  }

  function isLibrarySlugInvalid(dashView: DashView): boolean {
    // exit early if there are no relevant library slug data in dashView
    if (dashView.librarySlug === undefined) return false

    const libraryId = dashAccountsStore.librariesArray.find(
      (library: Library) => library.slug === dashView.librarySlug,
    )?.id
    if (libraryId !== undefined) return false

    return true
  }

  function isFolderIdInvalid(dashView: DashView): boolean {
    const collectionKey = dashView.collectionKey
    if (collectionKey == null || collectionKey.typeKey !== 'folderId') return false
    if (isNaN(collectionKey.indexKey)) return true

    return !dashCollectionsStore.foldersArray.some((folder: Folder) => folder.id === collectionKey.indexKey)
  }

  function isCollectionFilterInvalid(dashView: DashView): boolean {
    // Marked `filter=archived` as invalid if current account cannot see "archived".
    // This is for both mobile view and desktop view.
    if (!dashAccountsStore.canCurrentAccountArchiveWall && dashView.filter === 'archived') return true

    // We don't need filter param if selected filter is "Getting started".
    if (dashView.page === MobilePage.GettingStarted) return false

    // We don't need to check `filter` in mobile view because we show old dashboard
    if (windowSizeStore.isSmallerThanTabletLandscape) return false

    // Folder or user group folder view should not have filter param, so we don't validate it here.
    if (
      dashView.collectionKey?.typeKey === CollectionKeyTypes.FolderId ||
      dashView.collectionKey?.typeKey === CollectionKeyTypes.GroupFolderId
    ) {
      return false
    }

    if (dashView.filter === 'favorites') return false
    if (dashView.filter == null) return true
    if (dashView.accountKey?.type === 'library' && LIBRARY_ACCOUNT_FILTERS.includes(dashView.filter)) return false
    if (dashAccountsStore.isNativeAccount && USER_ACCOUNT_FILTERS.includes(dashView.filter)) return false
    if (!dashAccountsStore.isNativeAccount && TENANT_USER_ACCOUNT_FILTERS.includes(dashView.filter)) return false
    return true
  }

  function isNavigatedFromDashboardSettings(): boolean {
    const dashboardSettingsUrl = buildUrlFromPath('dashboard/settings')
    return document.referrer.startsWith(dashboardSettingsUrl)
  }

  function isMobilePageNavATabView(dashView: DashView): boolean {
    const mobilePageNavTabs = [
      MobilePage.AccountsMenu,
      MobilePage.Search,
      MobilePage.LayoutPicker,
      MobilePage.Join,
      MobilePage.More,
    ]
    return (
      windowSizeStore.isSmallerThanTabletLandscape &&
      dashView.mobilePage !== undefined &&
      mobilePageNavTabs.includes(dashView.mobilePage)
    )
  }

  /*
    When the user navigates to the dashboard, we update the current url to the default library
  */
  function updateCurrentUrlToDefaultLibrary(): void {
    const dashViewUrlFromStorage = getDashViewUrlFromStorage()

    if (dashViewUrlFromStorage !== null && isAppUsing('defaultDashboardLibraryNavigation')) {
      const currentUrl = new URL(window.location.href)
      const urlFromStorage = new URL(dashViewUrlFromStorage)
      // If pathname is not the same, we do not do anything
      if (urlFromStorage.pathname !== currentUrl.pathname) return
      // If the search params are the same, we do not do anything
      if (urlFromStorage.search === currentUrl.search) return

      currentUrl.search = urlFromStorage.search
      window.history.pushState(null, '', currentUrl.toString())
      saveDashViewUrlIntoStorage()
    }
  }

  return {
    // State
    currentTabKey,

    // Getters
    tabs,

    // Actions
    generateCurrentHomeUrl,
    generateCurrentGalleryUrl,
    generateMakeUrl,
    generateCurrentMakeUrl,
    generateURLFromAccount,
    switchToInitialTabAndView,
    switchTabAndViewFromDashView,
    switchTabAndViewFromUrlParams,
    redirectToHome,
    updateCurrentUrlToDefaultLibrary,
  }
})

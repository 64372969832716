// @file The store for giving suggested templates to the user.
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { DashStartingStateApi } from '@@/dashboard/padlet_api'
import { useDashGalleryTemplatesStore } from '@@/pinia/dash_gallery_templates_store'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { WallGalleryTemplate, WallSuggestedTemplatesData } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { sampleSize } from 'es-toolkit'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum GallerySuggestedTemplatesDataStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Error = 'Error',
}

export const useDashSuggestedTemplatesStore = defineStore('dashSuggestedTemplatesStore', () => {
  const MAX_NUM_SUGGESTED_TEMPLATES = 10

  const dashGalleryTemplatesStore = useDashGalleryTemplatesStore()
  const globalSnackbarStore = useGlobalSnackbarStore()

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const suggestedTemplatesData = ref<WallSuggestedTemplatesData>({} as WallSuggestedTemplatesData)
  const suggestedTemplatesDataStatus = ref(GallerySuggestedTemplatesDataStatus.Loading)

  const isSuggestedTemplatesLoaded = computed(
    (): boolean => suggestedTemplatesDataStatus.value === GallerySuggestedTemplatesDataStatus.Completed,
  )

  const suggestedTemplates = computed(() => {
    const galleryTemplates = dashGalleryTemplatesStore.galleryTemplates
    const recentTemplateIds = suggestedTemplatesData.value?.recentGalleryTemplates ?? []
    const suggestedTemplateIds = suggestedTemplatesData.value?.suggestedTemplateIds ?? []

    // get top MAX_NUM_SUGGESTED_TEMPLATES recent templates and suggested templates (prioritize recent templates)
    const numRecentTemplates = Math.min(MAX_NUM_SUGGESTED_TEMPLATES, recentTemplateIds.length ?? 0)
    const numSuggestedTemplates = MAX_NUM_SUGGESTED_TEMPLATES - numRecentTemplates
    // sampleSize requires the `size` param to be less than or equal to the size of `suggestedTemplateIds`
    const randomSuggestedTemplateIds = sampleSize(
      suggestedTemplateIds,
      Math.min(numSuggestedTemplates, suggestedTemplateIds.length),
    )

    const recentTemplates = galleryTemplates
      .filter((template: WallGalleryTemplate) => recentTemplateIds.includes(template.galleryTemplateId))
      .sort((a: WallGalleryTemplate, b: WallGalleryTemplate) => {
        return recentTemplateIds.indexOf(a.galleryTemplateId) - recentTemplateIds.indexOf(b.galleryTemplateId)
      })

    const suggestedTemplates = galleryTemplates.filter((template: WallGalleryTemplate) =>
      randomSuggestedTemplateIds.includes(template.galleryTemplateId),
    )
    return [...recentTemplates, ...suggestedTemplates]
  })

  async function fetchSuggestedTemplatesData(): Promise<void> {
    suggestedTemplatesDataStatus.value = GallerySuggestedTemplatesDataStatus.Loading
    try {
      const response = await DashStartingStateApi.fetchSuggestedTemplatesData()
      const data = response.data as JsonAPIResource<WallSuggestedTemplatesData>
      suggestedTemplatesData.value = data?.attributes
      suggestedTemplatesDataStatus.value = GallerySuggestedTemplatesDataStatus.Completed
    } catch (e) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error fetching suggested templates'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'DashSuggestedTemplatesStore' })
      suggestedTemplatesDataStatus.value = GallerySuggestedTemplatesDataStatus.Error
    }
  }

  return {
    suggestedTemplates,
    suggestedTemplatesData,
    suggestedTemplatesDataStatus,
    isSuggestedTemplatesLoaded,
    fetchSuggestedTemplatesData,
  }
})

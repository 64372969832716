// @file Helper functions for dashboard gallery templates.
import type { FilterGroup, TemplateFilters, WallGalleryTemplate } from '@@/types'
import { intersectionBy, uniqBy } from 'es-toolkit'
import Fuse from 'fuse.js'

const HIGHER_EDUCATION_GRADE_KEY = 'Higher ed'

/**
 * Returns a predicate function for the given filter key in the given group.
 * The predicate function is used to tell if a template matches the filter.
 */
function getFilterPredicateForGroup<G extends FilterGroup>(
  group: G,
  filterKey: string,
): (t: WallGalleryTemplate) => boolean {
  switch (group) {
    // Ensure a boolean value is returned by using `|| []` to handle undefined
    case 'grade':
      return (t: WallGalleryTemplate) => (t.grades ?? []).includes(filterKey)
    case 'category':
      return (t: WallGalleryTemplate) => (t.category ?? []).includes(filterKey)
    default:
      // TypeScript shouldn't let `group` to be none of the above values.
      // But the `case switch` syntax doesn't understand that.
      return () => true
  }
}

/**
 * Returns templates that match any of the filters in the given group (OR logic).
 */
function filterTemplatesByGroup<G extends FilterGroup>(
  templates: WallGalleryTemplate[],
  filters: TemplateFilters,
  group: G,
): WallGalleryTemplate[] {
  const filterGroup = filters[group]
  const allFilters = Object.keys(filterGroup)
  const selectedFilters = allFilters.filter((key) => filterGroup[key])
  if (selectedFilters.length === 0) return templates
  const results: WallGalleryTemplate[] = []
  selectedFilters.forEach((key) => {
    results.push(...templates.filter((p) => getFilterPredicateForGroup(group, key)(p)))
  })
  return results
}

/**
 * Returns templates that match all set of filters by groups (AND logic).
 */
function filterTemplates(templates: WallGalleryTemplate[], filters: TemplateFilters): WallGalleryTemplate[] {
  return intersectionBy(
    uniqBy(filterTemplatesByGroup(templates, filters, 'grade'), (t) => t.publicKey),
    uniqBy(filterTemplatesByGroup(templates, filters, 'category'), (t) => t.publicKey),
    // In non-production environment, all template walls share the same id
    // So we use`publicKey` as the unique identifier for templates for now
    (template) => template.publicKey,
  )
}

/**
 * Returns templates that match the given audience.
 */
function filterTemplatesByAudience(templates: WallGalleryTemplate[], audience: string): WallGalleryTemplate[] {
  return templates.filter((template) => template.audience === audience)
}

/**
 * Using Fuse.js to search through the templates through fuzzy search
 * https://fusejs.io/
 */
function filterBySearch(searchQuery: string, templates: WallGalleryTemplate[]): WallGalleryTemplate[] {
  if (searchQuery.length <= 0) return templates
  const fuseOptions = {
    keys: ['title', 'templateSynonyms', 'description'],
    threshold: 0.3, // Setting it low to get more relevant results
  }

  const fuse = new Fuse(templates, fuseOptions)
  // Mapping the results to get the original template object
  return fuse.search(searchQuery).map((result) => result.item)
}

export { filterBySearch, filterTemplates, filterTemplatesByAudience, HIGHER_EDUCATION_GRADE_KEY }

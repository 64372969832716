// @file Tools to handle BigInts

export function convertBigIntToNumber(value: any): any {
  // Convert bigint to number
  if (typeof value === 'bigint') return Number(value)

  // Recursively process each element in the array
  if (Array.isArray(value)) return value.map(convertBigIntToNumber)

  // Recursively process each property in the object
  if (value !== null && typeof value === 'object') {
    const newObj: { [key: string]: any } = {}
    for (const key in value) {
      if (Object.hasOwn(value, key)) {
        newObj[key] = convertBigIntToNumber(value[key])
      }
    }
    return newObj
  }

  // Return the value if it's not a bigint, array, or object
  return value
}

// @file The store for the filter logic in templates gallery on the dashboard.
// TODO: ENG-18718 Refactor to use pinia group by concern structure
import {
  filterBySearch,
  filterTemplates,
  filterTemplatesByAudience,
  HIGHER_EDUCATION_GRADE_KEY,
} from '@@/bits/dash_gallery_helper'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { setSearchParam } from '@@/bits/location'
import { snakeToKebabCase } from '@@/bits/sanitize_and_transform_text'
import { unboundedWatch, unboundedWatchEffect } from '@@/bits/vue'
import { UserAccountType } from '@@/enums'
import type { OzTabsWithSeparatorButtonProps } from '@@/library/v4/components/OzTabsWithSeparator.vue'
import { useDashAccountsStore } from '@@/pinia/dash_accounts_store'
import { useDashGalleryStore } from '@@/pinia/dash_gallery_store'
import { useDashGalleryTemplatesStore } from '@@/pinia/dash_gallery_templates_store'
import { useScreenReaderNotificationsStore } from '@@/pinia/screen_reader_notifications'
import type { WallGalleryTemplate } from '@@/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useDashGalleryFilterStore = defineStore('dashGalleryFilterStore', () => {
  const dashGalleryTemplatesStore = useDashGalleryTemplatesStore()
  const dashGalleryStore = useDashGalleryStore()
  const dashAccountsStore = useDashAccountsStore()
  const screenReaderNotificationsStore = useScreenReaderNotificationsStore()

  // State
  const selectedCategories = ref<string[]>([])
  const selectedGrades = ref<string[]>([])
  const searchQuery = ref<string>('')
  const isMobileFilterModalOpen = ref<boolean>(false)

  // Getters
  const currentAudienceFilteredTemplates = computed<WallGalleryTemplate[]>(() => {
    return filterTemplatesByAudience(dashGalleryTemplatesStore.galleryTemplates, selectedAudience.value)
  })

  // Extracting all the categories into an array
  const allCategories = computed<string[]>(() => {
    // We want the categories filter to show categories from education templates since only teachers can see the featured section
    const filteredTemplates = isFeaturedSection.value
      ? filterTemplatesByAudience(dashGalleryTemplatesStore.galleryTemplates, 'education')
      : currentAudienceFilteredTemplates.value

    return filteredTemplates.reduce((categories: string[], template: WallGalleryTemplate) => {
      template.category?.forEach((category) => {
        if (!categories.includes(category) && category !== 'Popular') {
          categories.push(category)
        }
      })
      return categories.sort()
    }, [])
  })

  // Map of all the categories with a boolean value to indicate if it is selected
  const categoriesSelectedMap = computed(() => {
    return allCategories.value.reduce((selected, category) => {
      selected[category] = selectedCategories.value.includes(category)
      return selected
    }, {})
  })

  // Helper function to sort grades
  function sortGrades(a, b): number {
    if (a === 'K') return -1
    if (b === 'K') return 1
    if (a === HIGHER_EDUCATION_GRADE_KEY) return 1
    if (b === HIGHER_EDUCATION_GRADE_KEY) return -1
    return parseInt(a) - parseInt(b)
  }

  // Extracting all the grades into an array
  const allGrades = computed(() => {
    // We want the grades filter to show grades from education templates since only teachers can see the featured section
    const filteredTemplates = isFeaturedSection.value
      ? filterTemplatesByAudience(dashGalleryTemplatesStore.galleryTemplates, 'education')
      : currentAudienceFilteredTemplates.value
    return filteredTemplates.reduce((grades: string[], template: WallGalleryTemplate) => {
      template.grades?.forEach((grade) => {
        if (!grades.includes(grade)) {
          grades.push(grade)
        }
      })
      return grades.sort(sortGrades)
    }, [])
  })

  // Map of all the grades with a boolean value to indicate if it is selected
  const gradesSelectedMap = computed(() => {
    return allGrades.value.reduce((selected, grade) => {
      selected[grade] = selectedGrades.value.includes(grade)
      return selected
    }, {})
  })

  const canSeeFeaturedSection = computed(() => {
    return isAppUsing('featuredSection') && dashAccountsStore.currentUserOptions.isTeacher === true
  })

  const defaultAudience = computed(() => {
    if (dashAccountsStore.currentUser == null) return 'general'
    if (canSeeFeaturedSection.value) return 'featured_gallery'
    if (
      dashAccountsStore.isBackpack ||
      dashAccountsStore.currentUser.account_type === UserAccountType.TEACHER ||
      dashAccountsStore.currentUser.account_type === UserAccountType.STUDENT ||
      dashAccountsStore.currentUser.account_type === UserAccountType.STAFF
    ) {
      return 'education'
    } else if (dashAccountsStore.currentUser.account_type === UserAccountType.BUSINESS) {
      return 'business'
    } else {
      return 'general'
    }
  })

  // Temporarily not getting the selected audience from localStorage, so that whoever can see the featured section will see it by default
  const selectedAudience = ref(defaultAudience.value)

  const allAudiences = computed<OzTabsWithSeparatorButtonProps[]>(() => [
    ...(canSeeFeaturedSection.value
      ? [
          {
            text: __('Featured'),
            key: 'featured_gallery',
            testId: 'featuredTab',
          },
        ]
      : []),
    {
      text: __('General'),
      key: 'general',
      testId: 'generalTab',
    },
    {
      text: __('Education'),
      key: 'education',
      testId: 'educationTab',
    },
    {
      text: __('Business'),
      key: 'business',
      testId: 'businessTab',
    },
  ])

  const isEducationAudience = computed(() => {
    return selectedAudience.value === 'education'
  })

  const isFeaturedSection = computed(() => {
    return selectedAudience.value === 'featured_gallery'
  })

  const isAudienceValid = (audience: string): boolean => {
    if (audience === null) return false
    return allAudiences.value.some((a) => a.key === audience)
  }

  const hasSearchQuery = computed(() => {
    return searchQuery.value.length > 0
  })

  const isSelectedCategoriesEmpty = computed(() => {
    return selectedCategories.value.length === 0
  })

  const isSelectedGradesEmpty = computed(() => {
    return selectedGrades.value.length === 0
  })

  const isFilterEmpty = computed(() => {
    return isSelectedCategoriesEmpty.value && isSelectedGradesEmpty.value && !hasSearchQuery.value
  })

  const xGradesFilter = computed(() => {
    return isEducationAudience.value || isFeaturedSection.value
  })

  // Actions
  function updateSelectedCategories(category: string): void {
    if (isFeaturedSection.value) {
      setSelectedAudience('education')
    }
    const index = selectedCategories.value.indexOf(category)
    if (index === -1) {
      // Add category if not already selected
      selectedCategories.value.push(category)
    } else {
      // Remove category if already selected
      selectedCategories.value.splice(index, 1)
    }
  }

  function updateSelectedGrades(grades: string | string[]): void {
    if (isFeaturedSection.value) {
      setSelectedAudience('education')
    }
    const gradesArray = Array.isArray(grades) ? grades : [grades]
    gradesArray.forEach((grade) => {
      const index = selectedGrades.value.indexOf(grade)
      if (index === -1) {
        selectedGrades.value.push(grade)
      } else {
        selectedGrades.value.splice(index, 1)
      }
    })
  }

  function updateSearchQuery(query: string): void {
    if (isFeaturedSection.value) {
      setSelectedAudience('education')
    }
    searchQuery.value = query
  }

  function resetCategories(): void {
    selectedCategories.value = []
  }

  function resetGrades(): void {
    selectedGrades.value = []
  }

  function resetSearch(): void {
    searchQuery.value = ''
  }

  function resetAllFilters(): void {
    resetCategories()
    resetGrades()
    resetSearch()
  }

  function toggleMobileFilterModal(): void {
    isMobileFilterModalOpen.value = !isMobileFilterModalOpen.value
  }

  function setSelectedAudience(audience: string, updateSearchParam: boolean = true): void {
    if (audience === selectedAudience.value || !isAudienceValid(audience)) return
    const audienceParamValue = snakeToKebabCase(encodeURIComponent(audience))
    if (updateSearchParam) setSearchParam('audience', audienceParamValue, false)
    selectedAudience.value = audience
    localStorage.setItem('templateGallerySelectedAudience', audience)
  }

  // Watch for changes to the filters and search query and update the current templates
  void unboundedWatchEffect(() => {
    const filteredTemplates = filterTemplates(currentAudienceFilteredTemplates.value, {
      grade: gradesSelectedMap.value,
      category: categoriesSelectedMap.value,
    })
    const searchResults = filterBySearch(searchQuery.value, filteredTemplates)
    dashGalleryStore.currentTemplates = searchResults
    screenReaderNotificationsStore.addScreenReaderMessage(
      __('%{numberOfTemplates} templates found', { numberOfTemplates: searchResults.length }),
    )
  })

  // Since it takes time for canSeeFeaturedSection to initialize, the initial value of defaultAudience might
  // not be the most accurate one. We should watch for changes in defaultAudience and set it to the correct value.
  void unboundedWatch(
    defaultAudience,
    (newDefaultAudience) => {
      selectedAudience.value = newDefaultAudience
    },
    { immediate: true },
  )

  return {
    // State
    selectedCategories,
    selectedGrades,
    searchQuery,
    isMobileFilterModalOpen,
    selectedAudience,
    // Getters
    allCategories,
    categoriesSelectedMap,
    allGrades,
    gradesSelectedMap,
    allAudiences,
    isSelectedCategoriesEmpty,
    isSelectedGradesEmpty,
    hasSearchQuery,
    isFilterEmpty,
    xGradesFilter,
    isFeaturedSection,
    canSeeFeaturedSection,
    defaultAudience,
    // Actions,
    updateSelectedCategories,
    updateSelectedGrades,
    updateSearchQuery,
    resetCategories,
    resetGrades,
    resetSearch,
    resetAllFilters,
    toggleMobileFilterModal,
    filterTemplates,
    filterTemplatesByAudience,
    filterBySearch,
    isAudienceValid,
    isEducationAudience,
    setSelectedAudience,
  }
})

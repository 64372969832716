// @file Dash accounts pinia store
import { ALERT_ICON } from '@@/bits/confirmation_dialog'
import getCsrfToken from '@@/bits/csrf_token'
import initialLibrary from '@@/bits/current_library'
import device from '@@/bits/device'
import { captureNonNetworkFetchError } from '@@/bits/error_tracker'
import window from '@@/bits/global'
import { greet } from '@@/bits/greeting'
import { __ } from '@@/bits/intl'
import { getOnboardingDataByTask } from '@@/bits/libraries_helper'
import { navigateTo } from '@@/bits/location'
import { buildLibraryIdCacheKey, buildUserIdCacheKey, fetchCachedQuery } from '@@/bits/query_client'
import { NATIVE_HOST } from '@@/bits/url'
import { displayNameForUser } from '@@/bits/user_model'
import { LibraryMembershipRole, LibraryType } from '@@/enums'
import { OzConfirmationDialogBoxButtonScheme } from '@@/library/v4/components/OzConfirmationDialogBox.vue'
import { useDashCollectionsStore } from '@@/pinia/dash_collections_store'
import { useDashNavigationStore } from '@@/pinia/dash_navigation_store'
import { useDashStore } from '@@/pinia/dash_store'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { usePersonalPlansStore } from '@@/pinia/personal_plans_store'
import {
  NATIVE_TENANT_ID,
  PADLET_COLLEGE_TENANT_ID,
  STARK_INDUSTRIES_LIBRARY_ID,
  useUserAccountsStore,
} from '@@/pinia/user_accounts_store'
import { useWindowSizeStore } from '@@/pinia/window_size'
import { fetchJson } from '@@/surface/api_fetch'
import type {
  AccountKey,
  AccountMenuItem,
  JsonApiData,
  JsonApiResponse,
  Library,
  LibraryId,
  LibraryMembership,
  OnboardingData,
  OnboardingTask,
  OnboardingTaskData,
  Tenant,
  TenantId,
  User,
  UserId,
  UserOptions,
} from '@@/types'
import type { NonCurrentTenantAccountsMenuItem } from '@@/types/dash'
import { MobilePage } from '@@/types/dash'
import type { JsonAPIResource } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'
import { isEqual, sortBy } from 'es-toolkit'
import { defineStore } from 'pinia'
import { computed, ref, toRaw } from 'vue'

type LibraryIdToUserRoleMap = Record<LibraryId, string>
const DEFAULT_AVATAR = 'https://padlet.net/avatars/tenant_avatar.webp'

export const useDashAccountsStore = defineStore('dashAccountsStore', () => {
  const userAccountsStore = useUserAccountsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()
  const dashCollectionsStore = useDashCollectionsStore()
  const dashStore = useDashStore()
  const windowSizeStore = useWindowSizeStore()
  const personalPlansStore = usePersonalPlansStore()
  const dashNavigationStore = useDashNavigationStore()

  function genericFetchError(payload: { error: any; source: string }): void {
    captureNonNetworkFetchError(payload.error, { source: payload.source })
    globalSnackbarStore.genericFetchError()
  }

  /**
   * ==================== PERSONAL ACCOUNTS ====================
   */

  const currentUserOptions = ref<UserOptions>({})

  const user = computed((): User => userAccountsStore.user)
  const usersById = computed((): Record<UserId, User> => userAccountsStore.usersById)
  const tenantsById = computed((): Record<TenantId, Tenant> => userAccountsStore.tenantsById)
  const currentAccountKey = computed((): AccountKey | null => dashCollectionsStore.currentAccountKey)
  const isCurrentAccountLibrary = computed((): boolean => currentAccountKey.value?.type === 'library')
  const canCurrentAccountArchiveWall = computed((): boolean => {
    return currentLibrary.value != null ? currentLibrary.value.isArchiveEnabled : currentUser.value.is_archive_enabled
  })
  const currentUser = computed((): User => userAccountsStore.currentUser)
  const canCreateWall = computed((): boolean => currentUser.value?.quota?.can_make)
  const isNativeAccount = computed((): boolean => currentUser.value?.tenant_id === NATIVE_TENANT_ID)
  const currentTenant = computed((): Tenant | null => userAccountsStore.currentTenant)
  const currentTenantName = computed((): string => currentTenant.value?.name ?? '')
  const currentTenantLogo = computed((): string =>
    currentTenant.value?.assets != null ? (currentTenant.value?.assets?.home_logo as string) : DEFAULT_AVATAR,
  )
  const isCurrentTenantPadletCollege = computed(
    (): boolean => currentTenant.value != null && currentTenant.value?.id === PADLET_COLLEGE_TENANT_ID,
  )
  const isBackpack = computed((): boolean =>
    currentTenant.value != null ? currentTenant.value.type === 'school' : currentUser.value.tenant_type === 'school',
  )
  const isBriefcase = computed((): boolean => currentTenant.value?.type === 'business')
  const isStudent = computed((): boolean => currentUser.value.role === 'student')
  const isTeacher = computed((): boolean => currentUser.value.role === 'teacher')
  const isAtLeastBackpackAdmin = computed(
    (): boolean => isBackpack.value && (currentUser.value.role === 'admin' || currentUser.value.role === 'owner'),
  )
  const userRolesByLibraryId = computed((): LibraryIdToUserRoleMap => {
    return userLibraryMemberships.value.reduce<LibraryIdToUserRoleMap>((accumulator, membership) => {
      accumulator[membership.libraryId] = membership.role
      return accumulator
    }, {})
  })
  const usersArray = computed((): User[] => userAccountsStore.usersArray)
  const accountNamesByUserId = computed((): Record<UserId, string> => {
    const hash: Record<UserId, string> = {}
    usersArray.value.forEach((user) => {
      const tenantId = user.tenant_id
      if (Number(tenantId) === NATIVE_TENANT_ID) {
        hash[user.id] = displayNameForUser(user)
      } else {
        hash[user.id] = tenantsById.value[tenantId]?.name
      }
    })
    return hash
  })
  const nonCurrentTenantAccountsMenuList = computed((): NonCurrentTenantAccountsMenuItem[] => {
    return usersArray.value
      .filter((user) => user.tenant_id !== currentTenant.value?.id)
      .map((user) => {
        const tenantId: TenantId = user.tenant_id
        const tenant: Tenant = tenantsById.value[tenantId]
        const tenantName: string = tenant !== undefined ? tenant.name : ''
        const tenantDomainName: string = tenant?.domain_name ?? NATIVE_HOST
        const isNative = tenantId === NATIVE_TENANT_ID
        const email = user.email
        const username = user.username as string
        const name = user.name as string
        const url = `https://${tenantDomainName}/dashboard`

        // if no uploaded tenant logo, logo is default padlet logo
        let logo = tenant?.assets != null ? (tenant.assets.home_logo as string) : DEFAULT_AVATAR
        if (isNative && user?.avatar !== undefined) logo = user.avatar

        return {
          name,
          username,
          email,
          logo,
          tenantName,
          tenantDomainName,
          isNative,
          url,
        }
      })
  })
  const accountsMenuList = computed((): AccountMenuItem[] => userAccountsStore.accountsMenuList)
  const transferableAccounts = computed<AccountMenuItem[]>(() =>
    accountsMenuList.value.filter((account) => !isEqual(account.key, toRaw(currentAccountKey.value))),
  )
  const dashNameString = computed((): string => {
    return __('Hi, %{userShortName}', {
      userShortName: currentUser.value?.short_name ?? currentUser.value?.username ?? '',
    })
  })
  const dashGreeting = computed((): string => greet(currentUser.value))

  function updateUserOptions(payload: { userOptions: UserOptions }): void {
    currentUserOptions.value = payload.userOptions
    // Default to true if canSearchWallsOnDashboard is not set for some reason
    if (currentUserOptions.value.canSearchWallsOnDashboard == null) {
      currentUserOptions.value.canSearchWallsOnDashboard = true
    }
  }

  function refreshWallQuotas(payload?: { forceQuotaRefresh: boolean }): void {
    userAccountsStore.refreshWallQuotas(payload)
  }

  async function initialize(): Promise<void> {
    await fetchAccounts()
    void fetchAllLibraryOnboardingData()
  }

  async function fetchAccounts(params?: { forceQuotaRefresh: boolean | undefined }): Promise<void> {
    await userAccountsStore.fetchAccounts(params)
  }

  async function fetchAllLibraryOnboardingData(): Promise<void> {
    if (isNativeAccount.value) await fetchUserLibraryMemberships()
    const promises: Array<Promise<void>> = []
    librariesArray.value.forEach((library) => {
      const currentRole = userRolesByLibraryId.value[library.id] as LibraryMembershipRole
      const onboardingAllowedRoles =
        library.libraryType === LibraryType.School
          ? [LibraryMembershipRole.Owner, LibraryMembershipRole.Admin]
          : [LibraryMembershipRole.Owner]
      if (onboardingAllowedRoles.includes(currentRole)) {
        promises.push(fetchLibraryOnboardingData(library.id))
      }
    })
    await Promise.all(promises)
  }

  /**
   * ==================== LIBRARY ACCOUNTS ====================
   */

  const userLibraryMemberships = ref<LibraryMembership[]>([])

  const librariesById = computed((): Record<LibraryId, Library> => userAccountsStore.librariesById)
  const wallViewableLibraryIds = computed((): LibraryId[] => userAccountsStore.wallViewableLibraryIds)
  const wallCreatableLibraryIds = computed((): LibraryId[] => userAccountsStore.wallCreatableLibraryIds)
  const currentLibrary = computed((): Library | null => {
    if (currentAccountKey.value == null) return null
    if (currentAccountKey.value.type === 'user') return null
    return librariesById.value[currentAccountKey.value.id] ?? initialLibrary
  })
  const isCurrentLibrarySchool = computed((): boolean => currentLibrary.value?.libraryType === LibraryType.School)
  const isCurrentLibraryClassroom = computed((): boolean => currentLibrary.value?.libraryType === LibraryType.Classroom)
  const isCurrentLibraryTeam = computed((): boolean => currentLibrary.value?.libraryType === LibraryType.Team)
  const isCurrentLibraryStarkIndustries = computed(
    (): boolean => isCurrentAccountLibrary.value && currentLibraryId.value === STARK_INDUSTRIES_LIBRARY_ID,
  )
  const currentLibraryDashboardPath = computed((): string => {
    if (currentLibrary.value?.dashboardUrl == null) return ''
    return new URL(currentLibrary.value?.dashboardUrl).pathname
  })
  const currentLibraryId = computed((): LibraryId | null => currentLibrary.value?.id ?? null)
  const defaultLibrary = computed((): Library | undefined => {
    // TODO: replace user with currentUser
    const defaultLibraryId = user.value.defaultLibraryId
    if (defaultLibraryId === undefined) return undefined

    // Personal library currently does not exist
    const PERSONAL_LIBRARY_ID = ''
    if (defaultLibraryId === PERSONAL_LIBRARY_ID) return undefined

    return librariesById.value[defaultLibraryId]
  })
  const isCurrentUserLibraryOwner = computed((): boolean => {
    if (currentLibrary.value == null) return false
    return currentUser.value.id === currentLibrary.value.ownerId
  })
  const canCurrentUserOnboardOntoLibrary = computed((): boolean => {
    if (currentLibrary.value == null) return false

    if (currentLibrary.value.libraryType !== LibraryType.School) {
      return isCurrentUserLibraryOwner.value
    }

    const currentRole = userRolesByLibraryId.value[currentLibrary.value.id] as LibraryMembershipRole

    return [LibraryMembershipRole.Owner, LibraryMembershipRole.Admin].includes(currentRole)
  })
  const isSingleVisibleLibrary = computed(() => {
    return wallViewableAndVisibleLibrariesArray.value.length === 0
  })
  const wallViewableAndVisibleLibrariesArray = computed(
    (): Library[] => userAccountsStore.wallViewableAndVisibleLibrariesArray,
  )
  const wallViewableLibrariesArray = computed((): Library[] =>
    wallViewableLibraryIds.value.map((id) => librariesById.value[id]),
  )
  const wallCreatableLibrariesArray = computed((): Library[] =>
    wallCreatableLibraryIds.value.map((id) => librariesById.value[id]),
  )
  const librariesArray = computed((): Library[] => userAccountsStore.librariesArray)
  const canViewCrossLibraryGallery = computed((): boolean => {
    return librariesArray.value.every((library) => library.libraryAccess?.canViewGallery)
  })
  const canCreateLibrary = computed((): boolean => {
    return !librariesArray.value.some((library) => library.libraryType === LibraryType.School)
  })

  async function fetchWallViewableLibraries(): Promise<void> {
    await userAccountsStore.fetchWallViewableLibraries()
  }

  async function fetchWallCreatableLibraries(): Promise<void> {
    await userAccountsStore.fetchWallCreatableLibraries()
  }

  async function fetchUserLibraryMemberships(): Promise<void> {
    try {
      await fetchCachedQuery<JsonApiResponse<LibraryMembership>>({
        cacheKey: ['fetchUserLibraryMemberships', buildUserIdCacheKey(currentUser.value.id)],
        queryFn: async () => await fetchJson(`/api/1/users/${currentUser.value.id}/libraries/memberships`),
        onFetchSuccess: (response) => {
          const libraryMemberships = (response?.data as Array<JsonApiData<LibraryMembership>>).map(
            (membership) => membership.attributes,
          )
          userLibraryMemberships.value = libraryMemberships
        },
      })
    } catch (error) {
      genericFetchError({ error, source: 'DashAccountsStoreFetchUserLibraryMemberships' })
    }
  }

  /**
   * ==================== ONBOARDING ====================
   */

  const onboardingDataByLibraryId = ref<Record<LibraryId, OnboardingData>>({})

  const activeOnboardingData = computed((): OnboardingData | {} => {
    if (currentAccountKey.value == null || !canCurrentUserOnboardOntoLibrary.value) return {}
    return onboardingDataByLibraryId.value[currentAccountKey.value.id]
  })
  const numOnboardingTasksCompleted = computed((): number => {
    const onboardingData = activeOnboardingData.value as OnboardingData
    if (onboardingData?.tasks === undefined) return 0

    return Object.values(onboardingData.tasks).reduce(
      (total, taskCompleted) => total + (taskCompleted === false ? 0 : 1),
      0,
    )
  })
  const onboardingTasksTotal = computed((): number => {
    const onboardingData = activeOnboardingData.value as OnboardingData
    if (onboardingData?.tasks === undefined) return 0

    return Object.values(onboardingData.tasks).length
  })
  const hasOnboardingTasks = computed((): boolean => onboardingTasksTotal.value > 0)
  const onboardingProgressString = computed((): string => {
    if (onboardingTasksAllCompleted.value) return __('Complete!')

    return __('%{tasksCompleted} of %{total}', {
      tasksCompleted: numOnboardingTasksCompleted.value,
      total: onboardingTasksTotal.value,
    })
  })
  const onboardingTasksAllCompleted = computed(
    (): boolean => numOnboardingTasksCompleted.value === onboardingTasksTotal.value,
  )
  const currentLibraryOnboardingTasksData = computed((): OnboardingTaskData[] => {
    if (currentAccountKey.value == null) return []
    if (!isCurrentAccountLibrary.value) return []
    if (
      onboardingDataByLibraryId.value == null ||
      Object.keys(onboardingDataByLibraryId.value).length === 0 ||
      onboardingDataByLibraryId.value[currentAccountKey.value.id]?.tasks == null
    )
      return []

    const tasksArray = Object.entries(onboardingDataByLibraryId.value[currentAccountKey.value.id]?.tasks)
    const libraryType = currentLibrary.value?.libraryType as LibraryType
    const tasksDataArray: OnboardingTaskData[] = tasksArray.map((taskTuple) => {
      const [task, taskCompleted] = taskTuple
      let taskData: OnboardingTaskData = getOnboardingDataByTask(task as OnboardingTask, libraryType)
      const isTaskDone = taskCompleted as boolean
      const hasTaskDoneAt = isTaskDone && typeof taskCompleted === 'string'
      taskData = {
        ...taskData,
        isTaskDone,
        taskDoneAt: hasTaskDoneAt ? taskCompleted : null,
      }
      if (taskData.key === 'pickPlan' && libraryType === LibraryType.Team) {
        return {
          ...taskData,
          taskStringOne: __('Upgrade your team'),
          taskStringTwo: __('Keep your team active when your free trial ends by upgrading to Team Gold.'),
        }
      }
      return taskData
    })

    return sortBy(tasksDataArray, ['order'])
  })
  const currentAccountName = computed((): string => {
    const activeCollectionKey = dashCollectionsStore.activeCollectionKey?.indexKey as string
    if (activeCollectionKey === 'combined_recents') {
      return ''
    } else if (activeCollectionKey === 'gallery') {
      return __('Gallery')
    } else if (['combined_shared', 'combined_shared_library', 'combined_shared_user'].includes(activeCollectionKey)) {
      if (
        !isNativeAccount.value ||
        (dashCollectionsStore.sharedCollections.length === 0 && !windowSizeStore.isSmallerThanTabletPortrait)
      )
        return ''
      return __('Shared')
    } else if (dashCollectionsStore.isActiveCollectionBookmark) {
      return __('Bookmarks')
    } else if (currentAccountKey.value?.type === 'user') {
      return accountNamesByUserId.value[currentAccountKey.value.id]
    } else if (currentAccountKey.value?.type === 'library') {
      return librariesById.value[currentAccountKey.value?.id]?.name
    } else {
      return ''
    }
  })

  function isOnboardingForLibrary(libraryId: LibraryId): boolean {
    if (!isNativeAccount.value) return false
    const onboardingData = onboardingDataByLibraryId.value[libraryId]
    return onboardingData !== undefined && onboardingData.completedAt == null
  }

  function navigateToOnboardingTask(payload: { taskKey: OnboardingTask }): void {
    if (currentAccountKey.value == null || !canCurrentUserOnboardOntoLibrary.value) return

    const target = device.electronApp ? '_blank' : '_self'
    switch (payload.taskKey) {
      case 'addNewTeam': {
        break
      }
      case 'customizeTeamInfo': {
        navigateTo(`/dashboard/settings/library_info?library_id=${currentAccountKey.value.id}`, {
          target,
        })
        break
      }
      case 'addMembers': {
        navigateTo(`/dashboard/settings/library_members?library_id=${currentAccountKey.value.id}`, {
          target,
        })
        break
      }
      case 'pickPlan': {
        navigateTo(`/dashboard/settings/library_billing?library_id=${currentAccountKey.value.id}`, {
          target,
        })
        break
      }
      case 'makePrivateWall': {
        createWallOrPromptUpgrade()
        break
      }
      case 'connectSchoolSso': {
        navigateTo(`/dashboard/settings/library_security?library_id=${currentAccountKey.value.id}`, {
          target,
        })
        break
      }
      case 'addAdmins': {
        navigateTo(`/dashboard/settings/library_members?library_id=${currentAccountKey.value.id}`, {
          target,
        })
        break
      }
      case 'addTeachersAndStudents': {
        navigateTo(`/dashboard/settings/library_members?library_id=${currentAccountKey.value.id}`, {
          target,
        })
        break
      }
      case 'manageUserPermissions': {
        navigateTo(`/dashboard/settings/library_permissions?library_id=${currentAccountKey.value.id}`, {
          target,
        })
        break
      }
    }
  }

  function createWallOrPromptUpgrade(): void {
    const allowWallCreation =
      currentUser.value == null || canCreateWall.value || (currentUserOptions.value.canMakeInLibrary as boolean)
    if (!allowWallCreation) {
      void personalPlansStore.quotaTriggeredUpgrade({ user: currentUser.value })
    } else {
      const makeUrl = dashNavigationStore.generateCurrentMakeUrl()
      window?.history?.pushState({ url: makeUrl }, '', makeUrl)
      dashNavigationStore.switchTabAndViewFromDashView({ make: 'make' })
    }
  }

  function confirmDismissOnboarding(): void {
    const afterConfirmActions = [
      dismissOnboarding,
      () => dashStore.switchView({ filter: 'all', mobilePage: MobilePage.Collection }),
    ]

    globalConfirmationDialogStore.openConfirmationDialog({
      ...ALERT_ICON,
      shouldFadeIn: true,
      title: __('Remove page?'),
      body: __('Are you sure you want to remove the Getting started page from dashboard?'),
      confirmButtonText: __('Remove'),
      cancelButtonText: __('Nevermind'),
      forceFullWidthButtons: true,
      buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
      afterConfirmActions,
    })
  }

  async function dismissOnboarding(): Promise<void> {
    if (currentAccountKey.value?.id === undefined) return
    try {
      const response = await fetchJson(`/api/1/libraries/${currentAccountKey.value.id}/onboarding/dismiss`, {
        method: HTTPMethod.post,
        csrfToken: getCsrfToken(),
      })
      setLibraryOnboardingData({
        libraryId: (response.data as JsonAPIResource<OnboardingData>).attributes.libraryId,
        onboardingData: (response.data as JsonAPIResource<OnboardingData>).attributes,
      })
    } catch (error) {
      genericFetchError({ error, source: 'DashAccountsStoreDismissOnboarding' })
    }
  }

  async function fetchLibraryOnboardingData(libraryId: LibraryId): Promise<void> {
    try {
      await fetchCachedQuery<JsonApiResponse<OnboardingData>>({
        cacheKey: [
          'fetchLibraryOnboardingData',
          buildUserIdCacheKey(currentUser.value.id),
          buildLibraryIdCacheKey(libraryId),
        ],
        queryFn: async () => await fetchJson(`/api/1/libraries/${libraryId}/onboarding`),
        onFetchSuccess: (response) => {
          const onboardingData = (response.data as JsonApiData<OnboardingData>).attributes
          onboardingData.tasks.addNewTeam = true
          setLibraryOnboardingData({ libraryId, onboardingData })
        },
      })
    } catch (error) {
      genericFetchError({ error, source: 'DashAccountsStoreFetchLibraryOnboardingData' })
    }
  }

  function setLibraryOnboardingData(payload: { libraryId: LibraryId; onboardingData: OnboardingData }): void {
    onboardingDataByLibraryId.value = {
      ...onboardingDataByLibraryId.value,
      [payload.libraryId]: payload.onboardingData,
    }
  }

  const xTakeATourButton = computed((): boolean => {
    return isCurrentTenantPadletCollege.value || isCurrentLibraryStarkIndustries.value
  })

  return {
    /**
     * ==================== PERSONAL ACCOUNTS ====================
     */
    user,
    usersById,
    tenantsById,
    currentUserOptions,
    currentAccountKey,
    isCurrentAccountLibrary,
    canCurrentAccountArchiveWall,
    currentUser,
    canCreateWall,
    isNativeAccount,
    currentTenant,
    currentTenantName,
    currentTenantLogo,
    isBackpack,
    isBriefcase,
    isStudent,
    isTeacher,
    isAtLeastBackpackAdmin,
    userRolesByLibraryId,
    usersArray,
    accountNamesByUserId,
    nonCurrentTenantAccountsMenuList,
    accountsMenuList,
    transferableAccounts,
    dashNameString,
    dashGreeting,
    updateUserOptions,
    refreshWallQuotas,
    initialize,

    /**
     * ==================== LIBRARY ACCOUNTS ====================
     */

    librariesById,
    wallCreatableLibraryIds,
    wallViewableLibraryIds,
    userLibraryMemberships,
    currentLibrary,
    currentLibraryDashboardPath,
    currentLibraryId,
    defaultLibrary,
    isCurrentUserLibraryOwner,
    canCurrentUserOnboardOntoLibrary,
    isSingleVisibleLibrary,
    isCurrentLibrarySchool,
    isCurrentLibraryClassroom,
    isCurrentLibraryTeam,
    isCurrentLibraryStarkIndustries,
    wallViewableAndVisibleLibrariesArray,
    wallViewableLibrariesArray,
    wallCreatableLibrariesArray,
    librariesArray,
    canViewCrossLibraryGallery,
    fetchWallViewableLibraries,
    fetchWallCreatableLibraries,
    fetchUserLibraryMemberships,
    canCreateLibrary,

    /**
     * ==================== ONBOARDING ====================
     */
    onboardingDataByLibraryId,
    activeOnboardingData,
    numOnboardingTasksCompleted,
    onboardingTasksTotal,
    hasOnboardingTasks,
    onboardingProgressString,
    onboardingTasksAllCompleted,
    currentLibraryOnboardingTasksData,
    currentAccountName,
    isOnboardingForLibrary,
    navigateToOnboardingTask,
    createWallOrPromptUpgrade,
    confirmDismissOnboarding,
    dismissOnboarding,
    fetchLibraryOnboardingData,
    setLibraryOnboardingData,
    xTakeATourButton,
  }
})

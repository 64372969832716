/**
 * @file Contains helpers to handle wall access settings.
 * */
import { WallAccessRight } from '@@/enums'

const WALL_ACCESS_RIGHT_TO_INTEGER_MAP = {
  [WallAccessRight.None]: 0,
  [WallAccessRight.Read]: 1,
  [WallAccessRight.Write]: 2,
  [WallAccessRight.Edit]: 4,
  [WallAccessRight.Administer]: 8,
}

export { WALL_ACCESS_RIGHT_TO_INTEGER_MAP }
